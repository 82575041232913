import firebase from 'firebase/compat/app';
import { store } from '../redux/store.js';
import { saveUser } from '../redux/userSlice.js';
import { db, auth } from './firebase-setup.js';

import { logOut } from './../redux/userSlice'
import { sleep } from '../utils/Utils.js';


export const createUserProfileDocument = async (userAuth, additionalData) => {
	if (!userAuth) return;

	// ' or " denote a string and ` denotes a template string. Template strings 
	// have some abilities that normal strings do not. Most importantly, you get
	// interpolation
	const docReference = db.doc(`/users/${userAuth.uid}`);

	const docSnapshot = await docReference.get();

	if (!docSnapshot.exists) {
		const createdAt = new Date();

		try {
			await docReference.set({
				...additionalData,
				createdAt
			})
		} catch (error) {
			console.log("error creating user profile", error.message);
		}
	}

	return docReference;
}



export async function reloadAuth() {
	// Por que temos reloadAuth *e* reloadUser?
	// TODO O correto é ter só um, isso não faz sentido.
	await auth.currentUser.reload()
    const user = await reloadUser()

	return user
}




export function guaranteeSubscription(user, navigate) {
	// Essa função é uma redundância de proteção, chamda por todas as telas do aplicativo.
	if (user) {
		if (user.subscription && user.subscription.isActive) {
			// TODO Mas e se foi modificada no documento do Firebase desde a última atualização de user?
			console.log(`guaranteeSubscription(): ok.`)
		}
		else {
			console.log("guaranteeSubscription(): user without subscription, going to userScreen")
			navigate("/user")
		}
	}
	else {
		console.log("guaranteeSubscription(): no user is signed in")
		navigate("/login")
	}
}



// TODO Isso aqui está redundante com algumas coisas de App.
export async function reloadUser() {
	// Forçamos atualização do state do usuário,
	// para garantir que está alinhado com o que temos no Firebase.
	//
	// TODO Só realizar a cada X minutos? Quantos downloads excessivos
	// isso aqui está gerando? Está atrasando alguma tela?
	//
	// De nota, temos um listener do FirebaseAuthe em App()
	// que recebe notificações (e.g., necessidade de deslogar
	// porque mudou a senha). Não tem (muita) relação com o que fazemos aqui.
	let uid, emailVerified;

	if (!auth.currentUser) {
		// Não temos usuário autenticado.
		// Para garantir a persistência, precisamos puxar a UID do state.

		// Antigamente, eu carregava as informações do state, fazendo uma persistência própria,
		// mas esse não é o modo correto: devemos usar a persistência do Firebase.
		//
		// Logo, "deslogamos" o usuário (i.e., limpamos o state), e avisamos.
		console.log('reloadUser(): no firebase auth; will load uid from state.')

		await sleep(1000)
		console.log('trying again')
		console.log(auth.currentUser)


		const user = store.getState().user.data
		uid = user.id
		emailVerified = user.isEmailVerified

		// signUserOut()
		// return false;
	}
	else {
		console.log('reloadUser(): loded uid from firebase auth')
		uid = auth.currentUser.uid
		emailVerified = auth.currentUser.emailVerified
	}

	const profile = await getUserProfile(uid, emailVerified)

	if (profile) {
		console.log('reloadUser(): user has profile; done.')
		updateUserState(profile)
		return profile;
	}
	else {
		console.log('reloadUser(): failed; will sign out.')
		signUserOut()
		return undefined;
	}
}




export function subscriptionIsActive(userData) {
	if (userData && userData.subscription && userData.subscription.isActive) {
		return true;
	}
	else {
		return false;
	}
}


export async function signUserOut() {
	console.log("User was signed out!")
	auth.signOut()
	store.dispatch(logOut())
}



export async function getUserProfile(uid, emailVerified) {
	try {
		const profileRef = db.doc(`/users/${uid}`);
		const profile = await profileRef.get();
	
		if (profile.exists) {    
			console.log('getUserProfile(): ok.')

			return serializeUserProfile(profile.data(), uid, emailVerified)
		}
		else {
			console.log(`getUserProfile(): failed.`)
			return undefined;
		}	
	}
	catch (error) {
		console.log("ERRO")
		console.log(error)
		return undefined;
	}
}


export function serializeUserProfile(profile_data, uid, emailVerified) {
	// Serialzizamos para não dar problema no state
	if (profile_data['createdAt']) {
		profile_data['createdAt'] = profile_data['createdAt'].toJSON();
	}

	return {
		id: uid,
		isEmailVerified: emailVerified, 
		...profile_data
	}
}



export function updateUserState(data) {
	store.dispatch(saveUser(data))
}



export function checkValidUser(user) {
	return user && Object.keys(user).length !== 0
}



export function checkActiveSubscription(user) {
	return checkValidUser(user) && user.subscription && user.subscription.isActive
}


export async function eraseSubscription(user) {
	const profileRef = db.doc(`/users/${user.id}`);
    
	await profileRef.update({
		'subscription': firebase.firestore.FieldValue.delete()
	})
}

export function shouldAllowPayment(subscriptionType) {
	// Objetivo é entender se o usuário já tem uma assinatura ativa ou não,
	// para decidir se ele pode ou não realizar uma nova compra.
	// export async function subscriptionIsActive(userData) {
	// Parâmetro é um dicionário compatível com o state do usuário,
	// igual ao retornado por getUserProfile().
	// return userData && userData.subscription && userData.subscription.isActive
	// }
	// É um pouco mais difícil do que parece.
	//
	// Casos que precisamos tratar:
	//		0. Não tem e nunca teve assinatura nenhuma
	//		1. Assinatura mensal: se válida, se cancelada, se pending payment
	//		2. PagarMe/Stripe pré-postback: se válida, se cancelada
	//		3. PagarMe pós-postback: se válida, se cancelada
	switch (subscriptionType) {
		case 'monthly-paid':
		case 'monthly-pending':
		case 'yearly-paid':
		case 'yearly-pending':
		case 'yearly-refused':
			return false;
		default:
			return true;
	}
}


export function getSubscriptionType(user) {
	function getSubscriptionStatus(user) {
		if ( checkValidUser(user) ) {
			if (!user.subscription) {
				return 'none'
			}
			else if ( user.subscription.pagarme_url ) {
				// Assinatura mensal da PagarMe
				switch (user.subscription.status) {
					case 'pending payment':
						return 'monthly-pending';
					case 'paid':
						return 'monthly-paid'
					default:
						return 'monthly-canceled'
				}
			}
			else if ( user.subscription.descriptor ) {
				// TODO Isso provavelmente não existe mais, desde 2024 (!), salvo que de alguém que não renovou sei lá
				// Assinatura semestral ou anual, via PagarMe ou Stripe, através de checkout link.
				if ( user.subscription.isActive ) {
					return 'yearly-paid';
				}
				else {
					return 'yearly-canceled';
				}
			}
			else if ( user.subscription.comment ) {
				// Assinatura semestral ou anual, via PagarMe, através do nosso site.
				if ( user.subscription.status === 'user canceled' || user.subscription.status === 'refunded') {
					return 'yearly-canceled';
				}
				else if ( user.subscription.status === 'paid' ) {
					return 'yearly-paid';
				}
				else if ( user.subscription.status === 'refused' ) {
					return 'yearly-refused';
				}
				else {
					return 'yearly-pending'
				}
			}
		}
		else {
			// Isso... não deveria ocorrer.
			// TODO? Throw algo? 
			console.log('ERRO BIZARRO')
			console.log(user)
			return undefined;
		}
	}


	let subscriptionType = getSubscriptionStatus(user)

	if (subscriptionType === 'yearly-paid') {
		if (user.subscription.validUntil === 'JAN-25') {
			subscriptionType += '-pending-renewal'
		}
	}

	return subscriptionType
}