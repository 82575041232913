import React, { useState, useEffect } from 'react'

import Logo from './../assets/Logo.png'
import './MaintenanceScreen.css'
import styled from 'styled-components'
import { ColumnCSS } from '../components/BasicComponents'



const Container = styled.div`
    ${ColumnCSS}
    justify-content: center;
    height: 100vh;
    width: 100%;
`

const Content = styled.div`
    width:  50%;
    text-align: center;
    @media (max-width: 768px) {
        width: 80%;
    }
`


const Serious = styled.p`
    color: black;
    font-size: 1em;
    margin-top: 1em;
`

const Detail = styled.p`
    font-weight: bold;
    color: gray;
    font-size: 0.9em;
    margin-top: 1em;
`


export default function MaintenanceScreen() {

    return (
        <Container>
            <Content>
                <img src = {Logo} className = 'maintenance-logo' />
                <p className = 'maintenance-title'>Manutenção</p>

                <Serious><b>Voltamos em breve.</b> Previsão: 8am.</Serious>

            </Content>
        </Container>
    )
}