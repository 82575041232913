import React, { useState, useEffect } from 'react'
import MaskInput from 'react-maskinput'
import styled, { css } from 'styled-components'
import Select from 'react-select'
import OslerButton from '../../components/OslerButton'

const FormGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 0em;
    width: 100%;
    margin: 0 0;

    padding-top: 2em;
`

const CardGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.8em;
    margin-top: 0.5em;
`

const SectionLabelDetail = styled.p`
    font-size: 1.0em;
    color: #666;
    margin-bottom: 1.5em;
`

const InputWrapper = styled.div`
    position: relative;
    width: 100%;
    margin: 0.5em 0;
`


const inputStyles = css`
    width: 100%;
    padding: 0.9em;
    font-size: 1em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    border: 0.5px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    outline: none;
    transition: all 0.2s;
    background: transparent;
    letter-spacing: 0.01em;
    caret-color: black;
    cursor: text;
    
    &:focus {
        border-color: rgba(0, 0, 0, 0.4);
    }
    
    @media (min-width: 701px) {
        &:focus ~ label,
        &:not(:placeholder-shown) ~ label {
            transform: translateY(-1.8em) translateX(0.5em) scale(0.85);
            padding: 0 0.2em;
            background: white;
            font-weight: 600;
        }
    }

    &::placeholder {
        color: transparent;
        transition: color 0.2s;
    }
    
    &:focus::placeholder,
    &:not(:placeholder-shown)::placeholder {
        color: #999;
    }

    @media (max-width: 700px) {
        font-size: 0.85em;
        &::placeholder {
            color: #999;
        }
    }
`


const StyledInput = styled.input`
    ${inputStyles}
`

const StyledMaskedInput = styled(MaskInput)`
    ${inputStyles}
`



// Estilização customizada para o react-select, para deixar igual aos inputs
const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    borderRadius: '8px',
    backgroundColor: 'transparent',

    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
    fontSize: '1em',
    padding: '0.5em',
  }),


}


const FloatingLabel = styled.label`
    position: absolute;
    left: 1em;
    top: 1.1em;
    color: #666;
    transition: all 0.2s;
    pointer-events: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    font-weight: 400;
    letter-spacing: 0.01em;

    @media (max-width: 700px) {
        display: none;
    }
`

const MobileLabel = styled.label`
    display: none;
    color: #666;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    font-weight: 500;
    font-size: 0.9em;
    margin-bottom: 0.5em;
    
    @media (max-width: 700px) {
        display: block;
    }
`



function createInput(label, value, setValue, mask, placeholder = "", halfWidth = false) {
    const wrapper = (children) => (
        <InputWrapper style={halfWidth ? { width: '100%' } : undefined}>
            <MobileLabel>{label}</MobileLabel>
            {children}
        </InputWrapper>
    )

    const input = mask ? 
        <StyledMaskedInput
            onChange={(event) => setValue(event.target.value)}
            mask={mask}
            maskString={mask}
            value={value}
            placeholder={placeholder}
            type="tel"
        />
        :
        <StyledInput
            placeholder={placeholder || "Digite aqui..."}
            type="text"
            value={value.replace(/[0-9]+/g, '').toUpperCase()}
            onChange={(e) => setValue(e.target.value)}
        />

    return wrapper(
        <>
            {input}
            <FloatingLabel>{label}</FloatingLabel>
        </>
    )
}



export function BoletoPaymentForm({ onDataChange }) {
    const [formData, setFormData] = useState({
        name: '',
        cpf: '',
        phone: '',
    })

    useEffect(() => {
        onDataChange(formData)
    }, [formData])

    const updateField = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }))
    }

    return (
        <FormGrid>
            <SectionLabelDetail>
              Coloque os dados de dono da conta corrente a partir da qual o boleto será pago. 
              Isso é importante para viabilizar a emissão da nota fiscal e, se desejado, o estorno.
            </SectionLabelDetail>

            {createInput(
                'Nome',
                formData.name,
                (value) => updateField('name', value),
                null,
                'William Osler'
            )}
            
            {createInput(
                'CPF',
                formData.cpf,
                (value) => updateField('cpf', value),
                '000.000.000-00',
                '000.000.000-00'
            )}
            
            {createInput(
                'Celular',
                formData.phone,
                (value) => updateField('phone', value),
                '+ 00 (00) 0 0000 0000',
                '+55 (DD) 9 0000 0000'
            )}
        </FormGrid>
    )
}

export function CreditCardPaymentForm({ paymentMethod, onDataChange, nInstallments }) {
    const [formData, setFormData] = useState({
        name: '',
        cpf: '',
        phone: '',
        cardNumber: '',
        cardExpiry: '',
        cardCvv: '',
        chosenInstallments: false,
    })

    useEffect(() => {
        onDataChange(formData)
    }, [formData])

    const updateField = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }))
    }

    function checkCardNumber(cardNumber) {
        // Se é um AmericanExpress, o CVV precisa ter quatro dígitos.
        if (cardNumber.substring(0, 2) === '34' || cardNumber.substring(0, 2) === '37') {
            return '0000'
        } else {
            return '000'
        }
    }

    return (
        <FormGrid>
            <SectionLabelDetail>
              Se está usando o cartão de um familiar, insira o nome e CPF dele, do contrário a transação será recusada 
              como "suspeita".
            </SectionLabelDetail>

            <SectionLabelDetail>Lembre-se: para o brasileiro, não há produto caro, só há parcela que não cabe no orçamento.
            </SectionLabelDetail>
            

            {createInput(
                'Nome no cartão',
                formData.name,
                (value) => updateField('name', value),
                null,
                'William Osler'
            )}
            

            <CardGrid>
                {createInput(
                    'CPF do titular',
                    formData.cpf,
                    (value) => updateField('cpf', value),
                    '000.000.000-00',
                    '000.000.000-00'
                )}
                
                {createInput(
                    'Celular',
                    formData.phone,
                    (value) => updateField('phone', value),
                    '+ 00 (00) 0 0000 0000',
                    '+55 (DD) 9 0000 0000'
                )}

            </CardGrid>

        
            <CardGrid>
                {createInput(
                    'Número do cartão',
                    formData.cardNumber,
                    (value) => updateField('cardNumber', value),
                    '0000 0000 0000 0000',
                    '1234 1234 1234 1234',
                )}

                {/* Envolvemos o Select com o InputWrapper e aplicamos os estilos customizados */}
                <InputWrapper>
                    <Select
                        placeholder="Número de parcelas"
                        options={nInstallments}
                        styles={customSelectStyles}
                        onChange={e => {
                            updateField('chosenInstallments', Number(e.value))
                        }} 
                    />

                {/* 
<Select 
                                    placeholder="Escolha o número de parcelas"
                                    options={nInstallments}
                                    onChange={e => {
                                        console.log(e)
                                        setChosenInstallments(Number(e.value))
                                    }} 
                                /> */}
                </InputWrapper>
            </CardGrid>
            
            <CardGrid>
                {createInput(
                    'Validade',
                    formData.cardExpiry,
                    (value) => updateField('cardExpiry', value),
                    '00/00',
                    'MM/AA'
                )}
                
                {createInput(
                    'Código de Segurança',
                    formData.cardCvv,
                    (value) => updateField('cardCvv', value),
                    checkCardNumber(formData.cardNumber),
                    checkCardNumber(formData.cardNumber)
                )}
            </CardGrid>

        </FormGrid>
    )
}
