import React, { useState } from 'react'
import styled, { css, keyframes } from 'styled-components'

import CreditCardIcon from './../../assets/credit_card_icon.png'
import BoletoIcon from './../../assets/boleto_icon.png'

const pulseGlow = keyframes`
    0% { box-shadow: 0 0 0 0 rgba(22, 114, 246, 0.2); }
    70% { box-shadow: 0 0 0 10px rgba(22, 114, 246, 0); }
    100% { box-shadow: 0 0 0 0 rgba(22, 114, 246, 0); }
`

const Container = styled.div`
    display: flex;
    gap: 1rem;
    width: 100%;
`

const MethodButton = styled.button`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 1rem;
    border: 2px solid;
    border-color: ${props => props.selected ? 'rgb(22, 114, 246)' : '#E5E7EB'};
    border-radius: 8px;
    background: ${props => props.selected ? 'rgba(22, 114, 246, 0.05)' : 'white'};
    cursor: pointer;
    transition: all 0.2s ease;
    flex: 1;

    ${props => props.selected && css`
        animation: ${pulseGlow} 2s infinite;
    `}

    &:hover {
        border-color: rgb(22, 114, 246);
        transform: translateY(-1px);
    }
`

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`

const IconImage = styled.img`
    width: 32px;
    height: 32px;
    
transition: filter 0.2s ease; // Transição apenas no filter
    filter: ${props => props.selected ? 'brightness(1)' : 'brightness(0.8)'};
`

const MethodName = styled.span`
    font-weight: 500;
    color: ${props => props.selected ? 'rgb(22, 114, 246)' : '#374151'};
    transition: color 0.2s ease;
    text-align: left;
`

const MethodInfo = styled.div`
    font-size: 0.85rem;
    color: #4B5563;
    text-align: left;
    line-height: 1.5;

    p {
        margin: 0;
    }

    strong {
        font-weight: 600;
    }
`

const paymentMethods = [
    {
        id: 'credit_card',
        name: 'Cartão de Crédito',
        icon: CreditCardIcon,
        info: (
            <p>
                Em <strong>até</strong> 12x, sem juros, usando o limite do cartão. 
                Acesso imediato.
            </p>
        )
    },
    {
        id: 'boleto',
        name: 'Boleto Bancário',
        icon: BoletoIcon,
        info: (
            <p>
                À vista (<strong>não</strong> há opção de parcelar). 
                Acesso após ~1 dia útil.
            </p>
        )
    }
]

export default function SelectPaymentMethod({ onSelect }) {
    const [selected, setSelected] = useState(null)

    const handleSelect = (methodId) => {
        if (methodId !== selected) {
            setSelected(methodId)
            onSelect(methodId)
        }
    }

    return (
        <Container>
            {paymentMethods.map(method => (
                <MethodButton
                    key={method.id}
                    selected={selected === method.id}
                    onClick={() => handleSelect(method.id)}
                >
                    <HeaderContainer>
                        <IconImage
                            src={method.icon}
                            alt={method.name}
                            selected={selected === method.id}
                        />
                        <MethodName selected={selected === method.id}>
                            {method.name}
                        </MethodName>
                    </HeaderContainer>
                    <MethodInfo>
                        {method.info}
                    </MethodInfo>
                </MethodButton>
            ))}
        </Container>
    )
}