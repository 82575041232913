import React from 'react'

import Logo  from './../assets/Logo.png'
import LogoDark from './../assets/LogoDark.png'
import Signature from './../assets/signature.png'
import SignatureDark from './../assets/signatureDark.png'
import PlaygroundIcon from './../assets/sandbox.png'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Session from '../controllers/Session'



const TestScreenHeaderContainer = styled.header`
    width: 100%;
    height: 5dvh;

    display: flex;
    flex-direction: row;
    justify-content: ${props => props.playground ? `flex-start` : `flex-start`};
    align-items: center;

    // Para garantir que a sombra apareça
    z-index: 2;


    ${props => {
        if (props.playground) {
            return props.theme.darkMode
                ? `
                    background: linear-gradient(45deg, #2c3e50, #4a69bd);
                `
                : `

      background: linear-gradient(
            45deg, 
                #87CEEB,  /* Azul céu claro, agora no início */
                #FFD700,  /* Amarelo dourado */
                #FFA500,  /* Laranja mais intenso */
                #FF9966   /* Laranja claro, agora no final */
        );
        `
        } else {
            return props.theme.darkMode
                ? `
                    background-color: rgba(61, 61, 61, 1);
                ` : `
                    background-color: white;

                    /* Sem isso, o box-shadow não aparece se o div abaixo tiver background-color */
                    
                    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
        `}
    }}

    

`

const HeaderLogoText = styled.img`
    margin: 0 0 0 0.5em;
    padding: 0;
    height: 2.5vh;
    filter: none !important; // para não ser afetado pelo dark mode
`

const HeaderLogoIcon = styled.img`
    height: 5vh;
    margin: 0 0 0 0.5em;
    filter: none !important; // para não ser afetado pelo dark mode
`


const PlaygroundIconCSS = styled.img`
    margin-left: 0.25em;
    height: 1.8em;
`


const PlaygroundText = styled.p`
    font-size: 1.5em;
    margin: 0;
`


export default function TestScreenHeader(props) {

    const darkMode = useSelector(state => state.theme.darkModeOn)

    const playgroundMode = Session.mode === 'playground-mode'

    return (
        <TestScreenHeaderContainer playground = {playgroundMode}> 
            { playgroundMode && 
                <>
                <PlaygroundIconCSS src = {PlaygroundIcon} />
                <HeaderLogoText src = {darkMode ? SignatureDark : Signature} />
                </>
            }
            { !playgroundMode && 
                <>
                <HeaderLogoIcon src = {darkMode ? LogoDark : Logo} />
                <HeaderLogoText src = {darkMode ? SignatureDark : Signature} />
                </>
            }
        </TestScreenHeaderContainer>
    )

}