import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import CheckoutInfo from './CheckoutInfo'
import CheckoutPayment from './CheckoutPayment'
import { theme } from './CheckoutStyles'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getSubscriptionType, reloadUser, shouldAllowPayment } from '../../firebase/firebaseUtils'
import { setIsLoading } from '../../redux/loadingSlice'
import { canBuyGivenProduct, PRODUCTS } from './CheckoutData'
import OslerButton from '../../components/OslerButton'
import { Column, ColumnCSS } from '../../components/BasicComponents'
import IsLoading from '../../main/IsLoading'
import { functions } from '../../firebase/firebase-setup'



const Container = styled.div`
    display: flex;
    min-height: 100vh;
    background: ${theme.colors.background};
    font-family: ${theme.fonts.primary};
    
    @media (max-width: 1200px) {
        flex-direction: column;
        // min-height: none;
    }
`


const NoticeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: ${theme.colors.background};
  padding: 1rem;
`

const NoticeCard = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem 3rem;
  max-width: 800px;
  width: 100%;
`

const NoticeTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333333;
  font-weight: bold;
text-align: center;
`

const NoticeText = styled.p`
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  line-height: 1.5;
  color: #555555;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`



const defaultBenefits = [
    '+47.000 flashcards baseados no UpToDate e em livros clássicos',
    '+43.000 questões, de todo o Brasil, todas comentadas',
    'qBank completo: provas na íntegra, lista de questões personalizadas, exportar em pdf, etc',
    'Simulados com questões próprias (2025)',
    'Estatísticas detalhadas e planos de revisão e recuperação',
    'Cronogramas para R1: Extensivo, Semi-Extensivo, Intensivão',
    'Material de multimídia completo: semiologia, ECG, exames de imagem, e exames complementares',
    'Crie resumos associados aos cards e questões',
    'Sistema de feedback e atualização contínua do conteúdo',
    'Desconto expressivo na renovação'
]



const aequanimitas = [
    'Mentoria Aequanimitas'
]


const latch_keyers = [
    'Mentoria Latch Keyers'
]


const basicoBenefits = [
    'Preço promocional de lançamento',
    'Flashcards do conteúdo do ciclo básico',
    'Sistema de feedback e atualização contínua do conteúdo',
    'Desconto quando entrar no ciclo clínico e no internato',
]


export const PRODUCTS_INFO = {
    // CICLO BÁSICO
    [PRODUCTS.JAN_26_BASICO]: {
        readablePrice: 'R$360,00',
        duration: 'Acesso até 31 de Janeiro de 2026',
        product: 'Osler Ciclo Básico',
        benefits: basicoBenefits
    },

    [PRODUCTS.JAN_26_BASICO_AEQUANIMITAS]: {
        readablePrice: 'R$360,00',
        duration: 'Acesso até 31 de Janeiro de 2026',
        product: 'Osler Ciclo Básico + Mentoria Aequanimitas',
        benefits: [...aequanimitas, ...basicoBenefits]
    },

    [PRODUCTS.JAN_26_BASICO_LATCH_KEYERS]: {
        readablePrice: 'R$360,00',
        duration: 'Acesso até 31 de Janeiro de 2026',
        product: 'Osler Ciclo Básico + Mentoria Latch Keyers',
        benefits: [...latch_keyers, ...basicoBenefits]
    },

    // PLANO COMUM
    [PRODUCTS.JAN_26_DEFAULT]: {
        readablePrice: 'R$3.600,00',
        duration: 'Acesso até 31 de Janeiro de 2026',
        product: 'Plataforma da Osler',
        benefits: defaultBenefits
    },

    [PRODUCTS.JAN_26_DEFAULT_AEQUANIMITAS]: {
        readablePrice: 'R$3.600,00',
        duration: 'Acesso até 31 de Janeiro de 2026',
        product: 'Plataforma da Osler + Mentoria Aequanimitas',
        benefits: [...aequanimitas, ...defaultBenefits]
    },

    [PRODUCTS.JAN_26_DEFAULT_LATCH_KEYERS]: {
        readablePrice: 'R$3.600,00',
        duration: 'Acesso até 31 de Janeiro de 2026',
        product: 'Plataforma da Osler + Mentoria Latch Keyers',
        benefits: [...latch_keyers, ...defaultBenefits]
    },

    // RENOVAÇÃO
    [PRODUCTS.JAN_26_RENEWAL]: {
        readablePrice: 'R$1.800,00',
        duration: 'Acesso até 31 de Janeiro de 2026',
        product: 'Plataforma da Osler',
        benefits: defaultBenefits
    },

    [PRODUCTS.JAN_26_RENEWAL_AEQUANIMITAS]: {
        readablePrice: 'R$ 797,00',
        duration: 'Acesso até 31 de Janeiro de 2026',
        product: 'Plataforma da Osler + Mentoria Aequanimitas',
        benefits: [...aequanimitas, ...defaultBenefits]
    },

    [PRODUCTS.JAN_26_RENWAL_LATCH_KEYERS]: {
        readablePrice: 'R$ 797,00',
        duration: 'Acesso até 31 de Janeiro de 2026',
        product: 'Plataforma da Osler + Mentoria Latch Keyers',
        benefits: [...latch_keyers, ...defaultBenefits]
    }
}



export default function Checkout() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const [productInfo, setProductInfo] = useState(null)

    const [activeCoupon, setActiveCoupon] = useState(false)
    const [hasActiveCoupon, setHasActiveCoupon] = useState(false)

    const [allowed, setAllowed] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const currentPlanProducts = useRef()
    const currentPlanID = useRef()


    const originalPlanData = useRef()
    const originalPlanID = useRef()


    function abort() {
        setAllowed(false)
        setProductInfo(false)
        setLoaded(true)
        dispatch(setIsLoading(false))
    }


    useEffect(() => {
        async function loadPlanFromPlanID() {
            dispatch(setIsLoading(true))

            const chosenPlan = location.state?.selectedPlan
           
            console.log(location)

            if (!chosenPlan) {
                abort()
                return
            }


            currentPlanProducts.current = chosenPlan.products

            console.log(chosenPlan)

            const planID = chosenPlan.planKey
            currentPlanID.current = planID
            originalPlanID.current = planID

            const getPlans = functions.httpsCallable('getTransactionPlan')
            const response = await getPlans({ planID: planID })

            const newUser = await reloadUser()
            const buyingAllowed = canBuyGivenProduct(newUser, planID)



            if (!response || !response.data || !buyingAllowed) {
                abort()
                return
            }
            else {
                originalPlanData.current = response.data
                setProductInfo(response.data)
                setAllowed(true)
                setLoaded(true)
            }

            dispatch(setIsLoading(false))
        }

        loadPlanFromPlanID()
    }, [])


    async function loadPlanFromCoupon(couponCode) {
        // Isso aqui é absolutamente irracional, é temporizador até migrarmos para o
        // servidor e termos uma estrutura de dados melhor.
        dispatch(setIsLoading(true))

        const getPlan = functions.httpsCallable('getTransactionPlanByCoupon')
        const result = await getPlan({ coupon_code: couponCode.trim(), plan_id: currentPlanID.current })

        if (result && result.data) {
            const newPlanID = result.data[0]
            const newPlanData = result.data[1]

            // Precisamos fazer o migué, e verificar se é do mesmp plano!

            let samePlan = false

            if (newPlanID.includes(currentPlanID.current)) {
                samePlan = true
            }
            else if ( newPlanID.startsWith('JAN-26-DEFAULT-B') && (
                currentPlanID.current === 'JAN-26-DEFAULT' || currentPlanID.current === 'JAN-26-RENEWAL'
            )) {
                // Só permite aplicar coupon de bolas de o atual for
                // JAN-26-DEFAULT ou JAN-26-RENEWAL
                samePlan = true
            }

            if (samePlan) {
                // Cupom existe e refere-se ao mesmo produto, podemos usar

                console.log('carregamos o cupom')
                setHasActiveCoupon(true)
                setActiveCoupon(couponCode)
                currentPlanID.current = newPlanID
                setProductInfo(newPlanData)
            }
            else {
                // Cupom existe, mas é de outro produto, não aceitamos, para não ficar confuso
                currentPlanID.current = originalPlanID.current
                setHasActiveCoupon(false)
                setActiveCoupon(false)
                setProductInfo(originalPlanData.current)
            }
        } else {
            currentPlanID.current = originalPlanID.current
            setHasActiveCoupon(false)
            setActiveCoupon(false)
            setProductInfo(originalPlanData.current)
        }

        dispatch(setIsLoading(false))
    }


    function onBuy() {
        navigate('/app')
    }


    return (
        <>
            {!loaded &&
                <NoticeContainer>
                    <IsLoading />
                </NoticeContainer>
            }
            {loaded && !allowed &&
                <NoticeContainer>
                    <NoticeCard>
                        <NoticeTitle>
                            Você não tem acesso ao que queria comprar.
                        </NoticeTitle>
                        <NoticeText>
                            Fizemos essa pausa para evitar fraudes, bugs ou que você compre a mesma coisa duas vezes por engano. Volte à tela de compra e recomece de lá.
                        </NoticeText>
                        <NoticeText>
                            Se acha que tem algo errado, entre em contato conosco na DM do Instagram ou via suporte@osler-ensino.com
                        </NoticeText>
                        <ButtonContainer>
                            <OslerButton
                                text="Voltar"
                                onClick={() => navigate('/buy')}
                            />
                        </ButtonContainer>
                    </NoticeCard>
                </NoticeContainer>
            }
            {loaded && allowed &&
                <Container>
                    <CheckoutInfo
                        products = {currentPlanProducts.current}
                        loadPlanFromCoupon={loadPlanFromCoupon}
                        hasActiveCoupon={hasActiveCoupon}
                        productInfo={productInfo} />

                    <CheckoutPayment
                        plan = {currentPlanID.current}
                        productInfo={productInfo}
                        activeCoupon = {activeCoupon}
                        onBuy={onBuy} />
                </Container>
            }

        </>
    )
}