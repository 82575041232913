import styled from 'styled-components'
import { ColumnCSS } from '../components/BasicComponents'


const PlanBoxContainer = styled.div`
    position: relative;
    background: white;
    border-radius: 16px;
    padding: 32px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
    transition: transform 0.2s ease-in-out;
    border: 2px solid ${props => props.highlighted ? '#3b82f6' : '#e2e8f0'};

    &:hover {
        transform: translateY(-4px);
    }
`

const PopularTag = styled.div`
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background: #3b82f6;
    color: white;
    padding: 4px 16px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const PlanTitle = styled.h2`
    font-size: 24px;
    color: #1e293b;
    margin-bottom: 16px;
    margin-top: ${props => props.highlighted ? '8px' : '0'};
    text-align: center;
`

const PlanPrice = styled.div`
    margin-bottom: 24px;
    text-align: center;
`

const Price = styled.span`
    font-size: 32px;
    font-weight: 700;
    color: #1e293b;
`

const PriceDetail = styled.div`
    color: #64748b;
    font-size: 14px;
    margin-top: 4px;
`

const PlanDescription = styled.p`
    font-size: 16px;
    color: #64748b;
    margin-bottom: 24px;
    line-height: 1.6;
    text-align: center;
`

const BenefitsList = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 32px;
`

const BenefitItem = styled.li`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
    color: #475569;

    &:before {
        content: "✓";
        color: #3b82f6;
        font-weight: bold;
    }
`

const BuyButton = styled.div`
    ${ColumnCSS}

    width: 100%;
    padding: 16px;
    background: ${props => props.highlighted ? '#3b82f6' : '#0f172a'};
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: ${props => props.highlighted ? '#2563eb' : '#1e293b'};
        transform: scale(1.02);
    }
`

const Content = styled.div`
    ${ColumnCSS}
    height: 100%;
    justify-content: space-between;
`

const Main = styled.div`
    ${ColumnCSS}
    height: 100%;
`

export default function PlanBox({ 
    plan,
    onPurchase
}) {

    function action() {
        onPurchase(plan)
    }

    return (
        <PlanBoxContainer highlighted={plan.highlighted}>
            {plan.highlighted && <PopularTag>Mais Popular</PopularTag>}
            
            <Content>
            <Main>
            <PlanTitle highlighted={plan.highlighted}>{plan.title}</PlanTitle>
            <PlanPrice>
                <Price>{plan.price}</Price>
                <PriceDetail>{plan.priceDetail}</PriceDetail>
            </PlanPrice>
            <PlanDescription>{plan.description}</PlanDescription>
            <BenefitsList>
                {plan.benefits.map((benefit, index) => (
                    <BenefitItem key={index}>
                        {benefit}
                    </BenefitItem>
                ))}
            </BenefitsList>

            </Main>


            <BuyButton 
                onClick={action}
                highlighted={plan.highlighted}
            >
                Quero este plano
            </BuyButton>
            </Content>
        </PlanBoxContainer>
    )
}