import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { db } from '../firebase/firebase-setup';
import firebase from 'firebase/compat/app';

import NiceButton from '../components/NiceButton';
import OslerButton from '../components/OslerButton';
import { sleep } from '../utils/Utils';
import { setIsLoading } from '../redux/loadingSlice';
import { getReadableProduct } from './checkout/CheckoutData';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3em;
  min-height: 100vh;
  background: #f5f5f5;
`;

const Card = styled.div`
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 900px;
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
`;

const Text = styled.p`
  color: #555;
  line-height: 1.5;
  margin-bottom: 1rem;
`;

const SecondaryText = styled.p`
  color: #777;
  font-size: 0.9rem;
  line-height: 1.4;
  margin-top: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.5rem 0;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1rem 0;
`;

const ListItem = styled.li`
  margin-bottom: 0.8rem;
`;

const Barcode = styled.span`
  font-family: monospace;
  background: #eee;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  user-select: text;
`;

const CopyButton = styled.button`
  margin-left: 0.5rem;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  background-color: #2779c4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #1b5a8a;
  }
`;

export default function PaymentProcessing({ loadScreen }) {
    const user = useSelector((state) => state.user.data);
    const dispatch = useDispatch();
    const [tries, setTries] = useState(0);

    // Função auxiliar para formatar datas (dd/mm/yyyy)
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('pt-BR');
    };

    async function tryLoadBoleto() {
        dispatch(setIsLoading(true));
        await sleep(2000);
        loadScreen()
        setTries((prev) => prev + 1);
        dispatch(setIsLoading(false));
    }

    async function cancelBoleto() {
        dispatch(setIsLoading(true));
        await db.collection('users').doc(user.id).update({
            subscription: firebase.firestore.FieldValue.delete(),
        });
        loadScreen()
        dispatch(setIsLoading(false));
    }

    function copyBarcode(barcode) {
        navigator.clipboard.writeText(barcode);
        alert('Código copiado!');
    }

    if (!user || !user.subscription) {
        return (
            <Container>
                <Card>
                    <Text>
                        Você não devia estar lendo isso. Manda um print para nosso suporte, imediatamente?
                    </Text>
                    <Text>Via DM (@oslermedicina) ou pelo email suporte@osler-ensino.com.</Text>
                </Card>
            </Container>
        );
    }

    const { paymentMethod, status, transaction_boleto, validUntil } = user.subscription;
    let content = null;

    if (paymentMethod === 'credit_card') {
        content = (
            <>
                <Title>Seu pagamento está em processamento!</Title>
                <Text>
                    Como você pagou com cartão, a liberação costuma ocorrer em menos de cinco minutos. Confira no app do banco se o
                    pagamento está ok e atualize a página daqui a pouco!
                </Text>
                <Text>
                    Se você acha que algo deu errado, entre em contato conosco via DM (@oslermedicina) ou, preferencialmente, pelo
                    email do suporte (suporte@osler-ensino.com).
                </Text>
            </>
        );
    } else if (paymentMethod === 'boleto') {
        if (status === 'processing') {
            const mainMsg =
                tries === 0 ? 'Seu boleto está sendo criado!' : 'Puts, ainda estamos criando seu boleto.';
            const subMsg =
                tries === 0
                    ? 'Em poucos minutos, ele estará disponível aqui, e também te enviaremos por email.'
                    : 'Pode demorar até 5 minutos. Bom momento para ir tomar (mais um) expresso. ☕';

            content = (
                <>
                    <Title>{mainMsg}</Title>
                    <Text>{subMsg}</Text>
                    <ButtonContainer>
                        <NiceButton
                            color="#2779c4"
                            style={{ width: '12em' }}
                            text="Ver se o boleto chegou"
                            action={tryLoadBoleto}
                        />
                    </ButtonContainer>
                    <Text>
                        Se você acha que algo deu errado ou está com alguma dúvida, entre em contato conosco via DM (@oslermedicina)
                        ou, preferencialmente, pelo email do suporte (suporte@osler-ensino.com).
                    </Text>
                </>
            );
        } else if (status === 'waiting_payment') {
            const expiration = formatDate(transaction_boleto.expiration);
            content = (
                <>
                    <Title>Seu boleto está pronto, só falta pagar!</Title>
                    <List>
                        <ListItem>
                            <strong>Código do boleto:</strong>{' '}
                            <Barcode>{transaction_boleto.barcode}</Barcode>
                            <CopyButton onClick={() => copyBarcode(transaction_boleto.barcode)}>
                                Copiar
                            </CopyButton>
                        </ListItem>
                        <ListItem>
                            <strong>Link para o boleto:</strong>{' '}
                            <a href={transaction_boleto.url} target="_blank" rel="noopener noreferrer">
                                Acessar boleto
                            </a>
                        </ListItem>
                        <ListItem>
                            <strong>Referente ao produto:</strong> {getReadableProduct(user)}
                        </ListItem>
                        <ListItem>
                            <strong>Pode ser pago até:</strong> {expiration}
                        </ListItem>
                    </List>
                    <SecondaryText>
                        Após pagar o boleto, você terá acesso assim que ocorrer a compensação (geralmente até no máximo 3 dias úteis).
                        Caso ainda não tenha pago e queira mudar o método de pagamento, você pode cancelar a solicitação.
                    </SecondaryText>
                    <ButtonContainer>
                        <OslerButton color="red" text="Cancelar" onClick={cancelBoleto} />
                    </ButtonContainer>
                </>
            );
        } else {
            content = (
                <>
                    <Text>Dados do boleto não reconhecidos. Entre em contato com o suporte.</Text>
                </>
            );
        }
    } else {
        content = (
            <>
                <Text>
                    Você não devia estar lendo isso. Manda um print para nosso suporte, imediatamente?
                </Text>
                <Text>Via DM (@oslermedicina) ou pelo email suporte@osler-ensino.com.</Text>
            </>
        );
    }

    return (
        <Container>
            <Card>
                {content}
            </Card>
        </Container>
    );
}
