import BlackArrow from './../assets/left-arrow-black.png'
import GreyArrow from './../assets/left-arrow-grey.png'

import BlackArrowDarkmode from './../assets/left-arrow-darkMode.png'
import GrayArrowDarkmode from './../assets/left-arrow-darkMode-hover.png'
import styled from 'styled-components'
import { useSelector } from 'react-redux'




const GoBackIcon = styled.img`
    width: 1.5em;
    cursor: pointer;
    @media (max-width: 500px) {
        width: 2em;
    }
`


export default function GoBackArrow({goBackAction, style}) {

    const darkMode = useSelector(state => state.theme.darkModeOn)


    return (
            <GoBackIcon 
                style = {style}
                onClick={goBackAction}
                src = {darkMode ? BlackArrowDarkmode : GreyArrow}
                onMouseOver = {e => e.currentTarget.src = darkMode ? GrayArrowDarkmode : BlackArrow}
                onMouseOut = {(e) => e.currentTarget.src = darkMode ? BlackArrowDarkmode : GreyArrow}
            />        
    )
}