import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SignatureLogo from './../../assets/signature.png'
import Aequanimitas from './../../assets/aequanimitas.png'
import PagarMe from './../../assets/payment/pagarme.png'
import Door from './../../assets/door.png'
import GoBackArrow from '../../components/GoBackArrow'
import OslerButton from '../../components/OslerButton'
import { auth } from '../../firebase/firebase-setup'
import { store } from '../../redux/store'
import { logOut } from '../../redux/userSlice'
import { valueToReadable } from '../../utils/Utils'

const theme = {
    colors: {
        primary: '#3182CE',
    },
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
    min-height: 100vh;
    background: linear-gradient(to bottom, #ffffff, #fafafa);
    padding: 3em 4em 2em 4em;
    position: relative;
    border-right: 1px solid rgba(0, 0, 0, 0.06);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(90deg, ${theme.colors.primary}, #2c5282);
    }

    @media (max-width: 1200px) {
        width: 100%;
        padding: 2em 15%;
        border-right: none;
        min-height: auto;
    }

    @media (max-width: 768px) {
        padding: 2em 5%;
    }
`

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`

const InfoSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2em;
`

const Card = styled.div`
    background: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 1em;
    padding: 2em;
    transition: 0.3s;
`

const PriceBox = styled(Card)`
    display: flex;
    flex-direction: column;
    gap: 1em;

    h2 {
        margin: 0;
        font-size: 2em;
        color: ${theme.colors.primary};
    }

    .original-price {
        text-decoration: line-through;
        color: #a0aec0;
        font-size: 1.1em;
    }

    .savings {
        font-size: 0.95em;
        background: #f0fdf4;
        border: 1px solid #bbf7d0;
        padding: 1em;
        border-radius: 0.5em;
        color: #166534;
        font-weight: 500;
    }
`

const CouponArea = styled.form`
    display: flex;
    flex-direction: column;
    gap: 0.6em;
    text-align: center;

    .coupon-row {
        display: flex;
        gap: 0.8em;

        input {
            flex: 1;
            padding: 0.8em;
            border: 1px solid #e2e8f0;
            border-radius: 0.4em;
            font-size: 1em;
            background: #f8fafc;
            text-align: center;
            caret-color: ${theme.colors.primary};
            cursor: text;

            &:focus {
                outline: none;
                border-color: ${theme.colors.primary};
                background: #fff;
            }
        }
    }
`


const CouponSuccess = styled.p`
    font-size: 1em;
    color: #166534;
`

const CouponError = styled.p`
    font-size: 1em;
    color: #b91c1c;
`

const CouponBttn = styled.div`
    padding: 0.8em 1.2em;
    background: ${theme.colors.primary};
    border: 1px solid ${theme.colors.primary};
    border-radius: 0.4em;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        background: #2c5282;
        border-color: #2c5282;
    }

`

const ProductsSection = styled(Card)`
    h2 {
        margin-top: 0;
        margin-bottom: 1em;
        font-size: 1.3em;
        color: #1e293b;
    }

    .product-list {
        display: flex;
        flex-direction: column;
        gap: 1.5em;
    }
`

const ProductItem = styled.div`
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 1em 0;

    margin-left: 3em;

    .logo {
        width: 140px;
        max-height: 60px;
        object-fit: contain;
    }

    .info {
        display: flex;
        flex-direction: column;
        gap: 0.3em;
        margin-left: 0em;

        h3 {
            margin: 0;
            font-size: 1.2em;
            color: #1e293b;
        }

        span {
            font-size: 1em;
            color: #475569;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        margin-left: 0;
    }
`

const Divider = styled.hr`
    border: none;
    border-top: 1px solid #a0aec0;
    margin: 1em 0;
`

const CheckoutFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
    gap: 2em;
    text-align: center;

    .account-badge {
        display: flex;
        align-items: center;
        gap: 1em;
        padding: 1em 1.5em;
        background: white;
        border: 1px solid #e2e8f0;
        border-radius: 1em;

        p {
            margin: 0;
            font-size: 0.95em;
            color: #475569;
        }
    }

    .payment-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5em;
        font-size: 0.9em;
        color: #475569;

        .payment-logo-ssl {
            display: flex;
            align-items: center;
            gap: 0.5em;
            margin: 0;
        }

        img {
            max-height: 50px;
            width: auto;
        }

        .ssl-text {
            font-size: 1em;
            color: #166534;
            margin: 0;
        }
    }

    .terms {
        font-size: 0.9em;
        color: #64748b;
        max-width: 500px;

        a {
            color: ${theme.colors.primary};
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
`


export default function CheckoutInfo({ loadPlanFromCoupon, hasActiveCoupon, productInfo, products }) {
    const navigate = useNavigate()
    const user = useSelector((state) => state.user.data)

    const [coupon, setCoupon] = useState('')
    const [originalPrice, setOriginalPrice] = useState(false)
    const [currentPrice, setCurrentPrice] = useState(false)
    const [discount, setDiscount] = useState(false)
    const [discountPercentage, setDiscountPercentage] = useState(false)

    const [hasTriedCoupon, setHasTriedCoupon] = useState(false)


    useEffect(() => {
        // O = original
        // A = atual
        const A = productInfo.amount / 100
        setCurrentPrice( valueToReadable(A) )

        if (productInfo.originalAmount) {
            const O = productInfo.originalAmount / 100
            const D = (O - A)
            const DP = 100 * (O - A) / O
    
            setOriginalPrice( valueToReadable(O) )
            setDiscount( valueToReadable(D) )
            setDiscountPercentage(`${DP.toFixed(0)}%`)
        } else {
            setOriginalPrice(false)
            setDiscount(false)
            setDiscountPercentage(false)
        }

    }, [productInfo])




    async function handleCoupon() {
        const code = coupon.trim().toUpperCase()
        await loadPlanFromCoupon(code)
        setHasTriedCoupon(true)
    }


    function changeUser() {
        auth.signOut()
        store.dispatch(logOut())
        navigate('/login')
    }

 
    // (
    //     <>
    //         Desconto de <b>R${' '}
    //         {discountValue.toLocaleString('pt-BR', {
    //             minimumFractionDigits: 2,
    //         })}{' '}
    //         ({(discount * 100).toFixed(0)}%)</b>
    //         {couponMsg.includes('sucesso') &&
    //         coupon.trim() !== '' ? (
    //             <> - Com o cupom {coupon.trim().toUpperCase()}</>
    //         ) : null}
    //     </>
    // ) : (
    //     <>
    //         Você economiza{' '}
    //         <b>
    //             R${' '}
    //             {totalSavings.toLocaleString('pt-BR', {
    //                 minimumFractionDigits: 2,
    //             })}
    //         </b>
    //     </>
    // )}


    return (
        <Container>
            <Main>
                <GoBackArrow
                    style={{ marginBottom: '2em' }}
                    goBackAction={() => navigate('/buy')}
                />
                <InfoSection>
                    <PriceBox>
                        <div className="original-price">
                            { originalPrice && originalPrice}
                        </div>
                        <h2>
                            { currentPrice }
                        </h2>
                        { discount && 
                            <div className="savings">
                                Desconto de <b>{discount} ({discountPercentage})</b> 
                            </div>
                        }
                        <CouponArea>
                            <div className="coupon-row">
                                <input
                                    type="text"
                                    placeholder="Tem um cupom?"
                                    value={coupon}
                                    onChange={(e) => setCoupon(e.target.value)}
                                />
                                <CouponBttn onClick = {handleCoupon}>Aplicar</CouponBttn>
                            </div>
                            { coupon && hasTriedCoupon && hasActiveCoupon && <CouponSuccess>Cupom {coupon} aplicado com sucesso!</CouponSuccess> }
                            { coupon && hasTriedCoupon && !hasActiveCoupon && <CouponError>Opa! Cupom não existe ou não é válido para este produto.</CouponError>}
                        </CouponArea>
                    </PriceBox>
                    <ProductsSection>
                        <h2>O que está incluído</h2>
                        <div className="product-list">
                            {products.map((product, index) => (
                                <React.Fragment key={index}>
                                <ProductItem>
                                    <img className="logo" src={product.icon} alt={product.title} />
                                    <div className="info">
                                    <h3>{product.title}</h3>
                                    <span>{product.detail}</span>
                                    </div>
                                </ProductItem>
                                {index < products.length - 1 && <Divider />}
                                </React.Fragment>
                            ))}
                            </div>
                    </ProductsSection>

                    
                </InfoSection>
            </Main>


            <CheckoutFooter>

                        <div className="account-badge">
                            <p>
                                Comprando como <b>{user?.email || 'seu-usuario@email'}</b>
                            </p>
                            <OslerButton
                                img={Door}
                                text="Trocar conta"
                                onClick={changeUser}
                                size="small"
                            />
                        </div>

                        <div className="terms">
                            Ao adquirir, você confirma que está de acordo com os{' '}
                            <a
                                href="https://firebasestorage.googleapis.com/v0/b/osler-web-app.appspot.com/o/Termos%20de%20Uso%20-%20Osler.docx.pdf?alt=media&token=05cc470b-9265-464b-9195-d22ac350893a"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                termos de uso e contrato de prestação de serviços
                            </a>
                            .
                        </div>

                        <div className="payment-info">
                            <span className="ssl-text">Pagamento processado com criptografia por</span>
                            <div className="payment-logo-ssl">
                                <img src={PagarMe} alt="PagarMe" />
                            </div>
                        </div>


                    </CheckoutFooter>
        </Container>
    )
}
