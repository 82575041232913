
import styled, { css } from 'styled-components'
import { theme, fadeIn } from './CheckoutStyles'
import { ColumnCSS } from '../../components/BasicComponents'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { setIsLoading } from '../../redux/loadingSlice'
import { functions } from '../../firebase/firebase-setup'
import { toastMsg } from '../../utils/Utils'
import { Element, scroller } from 'react-scroll'
import { useNavigate } from 'react-router-dom'
import SelectPaymentMethod from './SelectPaymentMethod'
import { BoletoPaymentForm, CreditCardPaymentForm } from './PaymentForm'


const PaymentContainer = styled.div`
    ${ColumnCSS}
    flex: 1;
    padding: 2.5em;
    animation: ${fadeIn} 1s ease-out;

    z-index: 3;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    @media (max-width: 1200px) {
        // Precisa ter no bottom, para desgrudar o botão
        padding: 4em;
        // background-color: yellow;
    }

    @media (max-width: 700px) {
        // Precisa ter no bottom, para desgrudar o botão
        padding: 2em;
    }
`

const ErrorMessage = styled.div`
    margin: 2em 0;
    padding: 1em;
    background: ${theme.colors.error}10;
    border: 1px solid ${theme.colors.error};
    border-radius: 0.5em;

    .CheckoutError-Header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 1em;
    }

    .CheckoutError-Title {
        font-weight: bold;
        font-size: 1.2em;
    }

    .CheckoutError-Close {
        cursor: pointer;
        padding: 0.5em;
    }

    @media (max-width: 600px) {
        margin: 2em 1em 1em 1em;

        .CheckoutError-Header {
            margin-bottom: 0em;
        }
    }
`

const ErrorP = styled.p`
    margin-top: 0em;

`

const ButtonContainer = styled.div`
    ${ColumnCSS}
    margin-top: 2em;
    
    @media (max-width: 600px) {
        margin-top: 0em;
        padding: 2em;
        width: 100%;
    }
`

const CheckoutSummary = styled.div`
  background-color: ${props =>
    props.theme.darkMode ? '#1c1c1c' : '#f9f9f9'};
  border-radius: 12px;
  /* Removemos a sombra para seguir o padrão dos outros elementos */
  box-shadow: none;
  padding: 2em;
  width: 70%;
  align-self: center;
  animation: ${fadeIn} 0.5s ease-out;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin-top: 2em; /* Afasta do topo */

  &:hover {
    transform: translateY(-3px);
    /* Mantemos sem sombra no hover */
    box-shadow: none;
  }

  /* Cabeçalho do resumo */
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2563eb; /* Novo tom primário */
    text-align: center;
    margin-bottom: 1em;
  }

  /* Lista de detalhes */
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0.8em 0;
    border-bottom: 1px solid ${props =>
      props.theme.darkMode ? '#333' : '#eaeaea'};
    font-size: 1rem;
    color: ${props => (props.theme.darkMode ? '#ccc' : '#333')};

    &:last-child {
      border-bottom: none;
    }

    /* Destaque para trechos importantes */
    strong {
      color: #2563eb; /* Novo tom primário */
    }
  }

  /* Caso haja mensagens de erro (como a classe .CheckoutError) */
  .CheckoutError {
    color: #e3342f;
    font-weight: bold;
  }

  @media (max-width: 600px) {
    width: 90%;
    padding: 1.5em;
    h3 {
      font-size: 1.3rem;
    }
    li {
      font-size: 0.9rem;
    }
  }
`

const BuyButton = styled.button`
  background: linear-gradient(to right, #2563eb, #1d4ed8);
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1.5rem 3rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  width: auto;
  position: relative;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(37, 99, 235, 0.3);
  }
  
  &:active {
    transform: translateY(0);
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background: linear-gradient(to right, #9ca3af, #6b7280);
    transform: none;
    box-shadow: none;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 1.25rem;
    font-size: 1.25rem;
  }
`




export default function CheckoutPayment({plan, onBuy, activeCoupon }) {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user.data)
    const [loaded, setLoaded] = useState(false)

    // Novo modelo 
    const [paymentFormData, setPaymentFormData] = useState({})
    const [paymentMethod, setPaymentMethod] = useState(false)
    const [nInstallments, setInstallments] = useState()
    const [errorMessage, setErrorMessage] = useState(false)
    const coupon = useRef("")
    const planData = useRef(undefined)


    const blockBuying = useRef(false)

    // O planID pode ser modificado se o usuário inserir um cupom
    // Mas nós guardamos o original para evitar o bug onde, ao
    // tentar confirmar o cupom ou inserir outro, não era reconhecido.
    const planID = useRef()
    const originalPlanID = useRef()

    const navigate = useNavigate()


    useEffect(() => {
        if (plan) {
            async function loadPlan() {
                dispatch(setIsLoading(true))

                planID.current = plan
                originalPlanID.current = plan
                
                const getPlans = functions.httpsCallable('getTransactionPlan')
                const response = await getPlans({ planID : planID.current})

                if (response && response.data) {
                    updatePlan(planID.current, response.data)
                    setLoaded(true)
                    dispatch(setIsLoading(false))
                }
                else {
                    // Não era para ocorrer, sugere que o plan está errado
                    createError(<ErrorP><b>Erro ao resgatar o plano.</b> Tente recarregar a página; qualquer coisa, envie uma DM.</ErrorP>)
                }
            }

            loadPlan()
        }
    }, [plan])



    useEffect(() => {
        console.log(activeCoupon)

        if (activeCoupon) {
            coupon.current = activeCoupon
        }

        console.log('cupom é')
        console.log(coupon.current)
    }, [activeCoupon])


    function updatePlan(paramPlanID, paramPlanData) {
        planID.current = paramPlanID
        planData.current = paramPlanData

        let options = []
        for (let i = 1; i <= planData.current.maxInstallments; i++) {
            options.push({
                value: `${i}`,
                label: getParcelasText(i)
            })
        }

        setInstallments( options )
    }


    function getReadablePrice() {
        return Math.round(planData.current.amount / 100).toLocaleString('pt-BR')
    }


    function getParcelasText(numberInstallments) {
        const price = planData.current.amount / 100
        const installmentPrice = Math.round(price / numberInstallments)
        const readablePrice = installmentPrice.toLocaleString('pt-BR')

        return (
            `${numberInstallments}x de R$${readablePrice}`
        )
    }


    async function buySubscription() {

        if (!paymentMethod) {
            createError(<ErrorP><b>Escolha um método de pagamento.</b></ErrorP>)
        }

        if (!isPersonalInfoValid()) {
            // Não criamos diálogo porque isso já é feito dentro da função.
            console.log("Dados pessoais inválidos")
            return;
        }

        // I.e., se for falso, se não está bloqueado
        // então permitimos a compra, mas bloqueamos
        // uma nova logo antes.
        if ( !blockBuying.current ) {
            blockBuying.current = true;
            if (paymentMethod === 'credit_card') {
                buyWithCard()
            }
            else {
                buyWithBoleto()
            }
        }
        else {
            // Isso nem deveria aparecer.
            createError(<ErrorP><b>Devagar, jovem.</b> Parece que você já tentou comprar. Cuidado para não clicar mais de uma vez e criar mais de uma transação por equívico.</ErrorP>)

            blockBuying.current = false;
        }
	}

    function isPersonalInfoValid() {
        if (!paymentMethod) {
            createError(<ErrorP><b>Escolha um modo de pagamento</b> Cartão (em até 12x) ou boleto (à vista), selecionando no topo.</ErrorP>)
            return false;
        }
        if (!paymentFormData.phone || paymentFormData.phone.trim() === "") {
            createError(<ErrorP><b>Preencha o celular.</b> O gateway de pagamento exige um celular válido.</ErrorP>)
            return false;
        }
        if (!paymentFormData.cpf || paymentFormData.cpf.trim() === "") {
            createError(<ErrorP><b>Você não preencheu o CPF.</b> Lembre que precisa ser o CPF do comprador.</ErrorP>)
            return false;
        }
        if (paymentFormData.cpf.length < 14) {
            createError(<ErrorP><b>Verifique se o CPF está correto.</b></ErrorP>)
            return false;
        }
        return true;
    }
    


    function isCreditCardDataValid() {
        if (!paymentFormData.name || paymentFormData.name.trim() === "") {
            createError(<ErrorP><b>Você não inseriu o <u>nome</u> do responsável pelo cartão!</b></ErrorP>)
            return false;
        }
        if (!paymentFormData.cardNumber || paymentFormData.cardNumber.toString().length < 16) {
            createError(<ErrorP><b>Atente-se ao <u>número do cartão</u>.</b> Algo parece errado.</ErrorP>)
            return false;
        }
        if (!paymentFormData.cardExpiry || paymentFormData.cardExpiry.toString().length < 4) {
            createError(<ErrorP><b>Atente-se à <u>data de expiração</u> do cartão.</b></ErrorP>)
            return false;
        }
        if (!paymentFormData.cardCvv || paymentFormData.cardCvv.toString().length < 3) {
            createError(<ErrorP><b>Atente-se ao <u>código de segurança</u> do cartão.</b></ErrorP>)
            return false;
        }
        if (!paymentFormData.chosenInstallments || paymentFormData.chosenInstallments < 1) {
            createError(<ErrorP><b>Escolha o número de parcelas.</b></ErrorP>)
            return false;
        }
        const parts = paymentFormData.cardExpiry.split('/')
        const month = parseInt(parts[0])
        const year = parseInt(parts[1])
        if (month <= 0 || month >= 13) {
            createError(<ErrorP><b>Mês de validade do cartão está errado.</b></ErrorP>)
            return false;
        }
        if (year <= 24) {
            createError(<ErrorP><b>Ano de validade do cartão está errado.</b></ErrorP>)
            return false;
        }
        return true
    }


    async function buyWithBoleto() {
        if (!user || !user.firstName || !user.email || !user.lastName) {
            // TODO Change this for a modal?
            toastMsg('Não conseguimos identificar quem está logado. Saia e entre de novo. Se o problema persistir, contate nosso suporte.')
			return;
		}

        try {
            // TODO TUD OISSO QUE ESTAMOS TESTANDO EM RESULT.DATA.DATA NÃO EXISTE. NÃO ESTAMOS MANDANDO DE VOLTA. E NO CARTÃO DE CRÉDITO TAMBÉM NÃO.
            console.log("Blocking to buy subscription")
            dispatch(setIsLoading(true))

			const buyPlan = functions.httpsCallable('boletoTransaction')
            const result = await buyPlan({
                chosenPlan: planID.current,
                coupon: coupon.current,
                customer: {
                    external_id: user.id,
                    name: user.firstName + " " + user.lastName,
                    email: user.email,
                    CPF: (paymentFormData.cpf.replace(/[.]/g, '')).replace(/-/g, ''),
                    phone: paymentFormData.phone.replace(/[(]/g, '').replace(/[)]/g, '').replace(/[ ]/g, '')
                },
            });
            
            // Não temos garantia, mesmo que tenha ocorrido algum erro, que não houve
            // mudança do perfil do usuário (e.g., pagamento correto, mas algum crash
            // inexplicável do Firebase). Para ser redundante, verificamos alterações
            // no profile de qualquer jeito.

            // onBuy()


            dispatch(setIsLoading(false))
            if (result.data.success) {
                console.log('boleto: Chamando onBuy()')
                onBuy()
                console.log(result)
            }
            else {
				console.log('buySubscription(): error')
                console.log(result)

                createError(<><p><b>Seu pagamento foi recusado.</b> O que é muito estranho, pois você está pagando com boleto. Por favor, tire um print e envie para o suporte.</p><p>Mais informações: {JSON.stringify(result.data)} </p></>)
			}

            blockBuying.current = false;
            
        } catch (error) {
            onBuy()

            dispatch(setIsLoading(false))

            console.log(error)

            createError(<><p><b>Seu pagamento foi recusado.</b> Por favor, confirme suas informações com calma, e envie uma foto dessa tela para o suporte se não conseguir resolver.</p><p>Mais informações: {JSON.stringify(error)} </p></>)

            blockBuying.current = false;
        }
    }



    async function buyWithCard() {
        if (!isCreditCardDataValid()) {
            console.log("Dados do cartão inválidos")
            blockBuying.current = false;
            return;
        }
        
		if (!user || !user.firstName || !user.email || !user.lastName) {
            // TODO Change this for a modal?
            toastMsg('Não conseguimos identificar quem está logado. Saia e entre de novo. Se o problema persistir, contate nosso suporte.')
			return;
		}        
        
        try {
            console.log("Blocking to buy subscription")
            dispatch(setIsLoading(true))

			const buyPlan = functions.httpsCallable('creditCardTransaction')
            const result = await buyPlan({
                chosenPlan: planID.current,
                coupon: coupon.current,
                card_number: paymentFormData.cardNumber.replace(/ /g, ''),
                card_cvv: paymentFormData.cardCvv,
                card_expiration_date: paymentFormData.cardExpiry.replace(/[/]/g, ''),
                card_holder_name: paymentFormData.name,
                installments: paymentFormData.chosenInstallments,
                customer: {
                    external_id: user.id,
                    name: user.firstName + " " + user.lastName,
                    email: user.email,
                    CPF: (paymentFormData.cpf.replace(/[.]/g, '')).replace(/-/g, ''),
                    phone: paymentFormData.phone.replace(/[(]/g, '').replace(/[)]/g, '').replace(/[ ]/g, '')
                },
            });

            dispatch(setIsLoading(false))

            /*
                O objeto result contém o objeto data, que contém os dados retornados pelo Firebase.
                Hoje, data contém os campos:
                    success - booleano - indica se a transação deu certo ou não
                    msg - string - uma mensagem explicando qual erro ocorreu, e que podemos mostrar ao usuário
                    code - string - uma codificação do tipo de erro, para identificarmos e tratarmos casos específicos
            */
            console.log(result)
            onBuy('credit_card', planData.current.amount, coupon.current)

            if (result.data.success) {
                console.log(result)
            }
            else {
				console.log('buySubscription(): error')
                console.log(result)

                // A realidade é que isso aqui raramente ocorre. Na maioria das vezes, vai para o pending payment (!)

                if (result.data.code === 'duplicated_transaction') {
                    createError(<><b>Parece que você já tentou comprar.</b> Para sua segurança, bloqueamos com o intuito de evitar uma transação duplicada. Recarregue a página e, se o erro persistir, envie um e-mail para suporte@osler-ensino.com que retornaremos em menos de 24 horas.</>)
                }
                else {
                    createError(<>
                        <p><b>Seu pagamento foi recusado.</b> Com cartão, é comum que ocorra. A seguir, as principais causas.</p>                
                        
                        <p><b>Tem limite?</b> É dura a vida do interno. Você precisa ter o limite do valor cheio. Se não tiver, é comum usar o cartão de algum familiar.</p>
    
                        <p><b>Você preencheu os dados do dono do cartão?</b> Lembre-se, precisamos do nome que está aparecendo no cartão, e do CPF dessa pessoa. Se o cartão é de um familiar, não adianta colocar seus dados.</p>
    
                        <p><b>O cartão é ELO?</b> Infelizmente, não é aceito.</p>
    
                        <p><b>O cartão bloqueou?</b> É relativamente comum o banco bloquear a transação por excesso de zelo. Ligue para eles.</p>
    
                        <p><b>Não é nada disso?</b> Mande um alô para a gente, via suporte@osler-ensino.com <u>enviando um print dessa tela</u>.</p>
    
                        <p>Mais informações: {JSON.stringify(result.data)} </p>
                    </>)
                }
			}

            blockBuying.current = false;
            
        } catch (error) {
            onBuy('credit_card', planData.current.amount, coupon.current)

            dispatch(setIsLoading(false))

            console.log(error)

            createError(<><p><b>Seu pagamento foi recusado.</b> Por favor, confirme suas informações com calma, e envie uma foto dessa tela para o suporte se não conseguir resolver.</p><p>Mais informações: {JSON.stringify(error)} </p></>)

            blockBuying.current = false;
        }
    }
	


    function setMethod(chosenMethod) {
        setPaymentMethod(chosenMethod)
        createError(false)
    }


    function isAnyFieldEmpty() {
        if (paymentMethod === 'credit_card') {
            return (
                !paymentFormData.name ||
                !paymentFormData.cardNumber ||
                !paymentFormData.cardExpiry ||
                !paymentFormData.cardCvv ||
                !paymentFormData.phone ||
                !paymentFormData.cpf
            );
        } else if (paymentMethod === 'boleto') {
            return !paymentFormData.phone || !paymentFormData.cpf;
        }
        return true;
    }
    


    function createError(messageJSX) {
        if (!messageJSX) {
            setErrorMessage(false)
        }
        else {
            console.log('oi')
            setErrorMessage(messageJSX)
            scroller.scrollTo('CheckoutPage-Error', {
                duration: 100,
                delay: 0,
                smooth: true,
                offset: -50, // Scrolls to element + 50 pixels down the page
              })
        }
    }


    return (
        <>
        { loaded && (
            <PaymentContainer>             
                <SelectPaymentMethod
                    onSelect={(method) => setMethod(method)} />
                    
                { paymentMethod === 'credit_card' &&
                    <CreditCardPaymentForm 
                        nInstallments = {nInstallments}
                        onDataChange={(data) => {
                            console.log(data)
                            setPaymentFormData(data)
                    }} />
                }
                { paymentMethod === 'boleto' && 
                    <BoletoPaymentForm
                        onDataChange={(data) => {
                            setPaymentFormData(data) 
                    }} />                
                }

    
                <CheckoutSummary>
                    <p><strong>DETALHES DA COMPRA</strong></p>
                    <ul>
                        <li>Acesso completo até <strong>{planData.current.validUntilFormatted}</strong>.</li>
    
                        {!paymentMethod && (
                            <li><strong className="CheckoutError">Escolha um método de pagamento.</strong></li>
                        )}
    
                        {paymentMethod === 'credit_card' && (
                            <li>Pagamento via cartão de crédito.</li>
                        )}
    
                        {paymentMethod === 'credit_card' && !paymentFormData.chosenInstallments && isAnyFieldEmpty() && (
                            <li><strong className="CheckoutError">Insira seus dados e escolha o número de parcelas.</strong></li>
                        )}
    
                        {paymentMethod === 'credit_card' && paymentFormData.chosenInstallments && isAnyFieldEmpty() && (
                            <li><strong className="CheckoutError">Falta inserir os dados.</strong></li>
                        )}
    
                        {paymentMethod === 'credit_card' && !paymentFormData.chosenInstallments && !isAnyFieldEmpty() && (
                            <li><strong className="CheckoutError">Escolha o número de parcelas.</strong></li>
                        )}
    
                        {paymentMethod === 'credit_card' && paymentFormData.chosenInstallments && (
                            <li><strong>{getParcelasText(paymentFormData.chosenInstallments)}</strong> (total de <strong>R${getReadablePrice()}</strong>).</li>
                        )}
    
                        {paymentMethod === 'boleto' && (
                            <>
                            <li>Pagamento via boleto.</li>
                            <li>Valor <strong>à vista</strong> de <strong>R${getReadablePrice()}</strong>.</li>
                            </>
                        )}
                        
                        {paymentMethod === 'boleto' && isAnyFieldEmpty() && (
                            <li><strong className="CheckoutError">Falta inserir seus dados.</strong></li>
                        )}
                    </ul>
                </CheckoutSummary>
    
                {errorMessage && (
                    <ErrorMessage>
                        <div className="CheckoutError-Header">
                            <div>
                                <p className="CheckoutError-Title">
                                    <span className="CheckoutError-Emoji">⚠️</span>
                                    Habemus quaestio
                                </p>
                                <p className="CheckoutError-Detail">Porque não basta dar problema, tem que ter Latim no meio.</p>
                            </div>
    
                            <div className="CheckoutError-Close" onClick={() => setErrorMessage(false)}>
                                X
                            </div>
                        </div>
    
                        {errorMessage}
                    </ErrorMessage>
                )}
    
                <ButtonContainer>    
                    <BuyButton 
                        onClick={buySubscription}
                        disabled={isAnyFieldEmpty() || !paymentMethod}>
                            Comprar agora
                    </BuyButton>
                </ButtonContainer>
    
                <Element name="CheckoutPage-Error" />
            </PaymentContainer>
        )}
        </>
    )
}