








export const PRODUCTS = {
    NONE: 'NONE',

    JAN_25_OVERDUE: 'JAN-25-OVERDUE',

    JAN_26_DEFAULT: 'JAN-26-DEFAULT',
    JAN_26_RENEWAL: 'JAN-26-RENEWAL',
    JAN_26_BASICO: 'JAN-26-BASICO',

    JAN_26_BASICO_AEQUANIMITAS: 'JAN-26-BASICO-AEQUANIMITAS',
    JAN_26_BASICO_LATCH_KEYERS: 'JAN-26-BASICO-LATCH_KEYERS',

    JAN_26_DEFAULT_AEQUANIMITAS: 'JAN-26-DEFAULT-AEQUANIMITAS',
    JAN_26_DEFAULT_LATCH_KEYERS: 'JAN-26-DEFAULT-LATCH_KEYERS',

    JAN_26_RENEWAL_AEQUANIMITAS: 'JAN-26-RENEWAL-AEQUANIMITAS',
    JAN_26_RENEWAL_LATCH_KEYERS: 'JAN-26-RENEWAL-LATCH_KEYERS',


    JAN_26_DEFAULT_UPSELL_AEQUANIMITAS : 'JAN-26-DEFAULT-UPSELL-AEQUANIMITAS',
    JAN_26_DEFAULT_UPSELL_LATCH_KEYERS : 'JAN-26-DEFAULT-UPSELL-LATCH_KEYERS',

    JAN_26_DEFAULT_B1: 'JAN-26-DEFAULT-B1',
    JAN_26_DEFAULT_B2: 'JAN-26-DEFAULT-B2',
    JAN_26_DEFAULT_B3: 'JAN-26-DEFAULT-B3',
    JAN_26_DEFAULT_B4: 'JAN-26-DEFAULT-B4',
    JAN_26_DEFAULT_B5: 'JAN-26-DEFAULT-B5',
    JAN_26_DEFAULT_B6: 'JAN-26-DEFAULT-B6',
    JAN_26_DEFAULT_B7: 'JAN-26-DEFAULT-B7',
    JAN_26_DEFAULT_B8: 'JAN-26-DEFAULT-B8',
    JAN_26_DEFAULT_B9: 'JAN-26-DEFAULT-B9',
    JAN_26_DEFAULT_B10: 'JAN-26-DEFAULT-B10',
    JAN_26_DEFAULT_B11: 'JAN-26-DEFAULT-B11',
    JAN_26_DEFAULT_B12: 'JAN-26-DEFAULT-B12',
    JAN_26_DEFAULT_B13: 'JAN-26-DEFAULT-B13',
    JAN_26_DEFAULT_B14: 'JAN-26-DEFAULT-B14',
    JAN_26_DEFAULT_B15: 'JAN-26-DEFAULT-B15',
    JAN_26_DEFAULT_B16: 'JAN-26-DEFAULT-B16',

}



export function getReadableProduct(user) {
    const plan = user.subscription?.validUntil;

    // Primeiro, checamos se o plano começa com JAN-26-DEFAULT-B1
    if (plan?.startsWith('JAN-26-DEFAULT-B1')) {
        return 'Osler até 31 de Janeiro de 2026 (bolsista)'
    }

    // Depois continuamos com o switch para os outros casos
    switch (plan) {
        case 'JAN-25':
            return 'Osler até 31 de Janeiro de 2025'

        case 'JAN-26':
        case 'JAN-26-DEFAULT':
        case 'JAN-26-RENEWAL':
            return 'Osler até 31 de Janeiro de 2026'

        case 'JAN-26-BASICO':
            return 'Osler Ciclo Básico até 31 de Janeiro de 2026'

        case 'JAN-26-BASICO-AEQUANIMITAS':
            return 'Osler Ciclo Básico + Mentoria Aequanimitas até 31 de Janeiro de 2026'

        case 'JAN-26-BASICO-LATCH_KEYERS':
            return 'Osler Ciclo Básico + Mentoria Latch Keyers até 31 de Janeiro de 2026'

        case 'JAN-26-DEFAULT-AEQUANIMITAS':
        case 'JAN-26-RENEWAL-AEQUANIMITAS':
        case 'JAN-26-DEFAULT-UPSELL-AEQUANIMITAS':
            return 'Osler + Mentoria Aequanimitas até 31 de Janeiro de 2026'

        case 'JAN-26-DEFAULT-LATCH_KEYERS':
        case 'JAN-26-RENEWAL-LATCH_KEYERS':
        case 'JAN-26-DEFAULT-UPSELL-LATCH_KEYERS':
            return 'Osler + Mentoria Latch Keyers até 31 de Janeiro de 2026'
        
        default:
            return '(não conseguimos identificar; não deve ser nada demais, mas fale conosco na DM ou via email)'
    }
}


export function getCurrentProduct(subscription) {
    if (!subscription) return PRODUCTS.NONE

    if (subscription.pagarme_url) {
        if (!subscription.validUntil) {
            // Significa que é o mensal e não comprou nada até agora. Permitimos comprar
            // mentoria se estiver com a mensalidade me dia.
            if (subscription.status === 'paid') {
                return PRODUCTS.JAN_26_DEFAULT
            }
            else {
                return PRODUCTS.NONE
            }
        }
        else {
            // Significa que é o mensal e já comprou algo novo
            return subscription.validUntil
        }
    }

    // Mudamos para evitar que upsell quebre
    // const active = subscription.status === 'paid' && subscription.isActive
    const active = subscription.isActive
    
    if (!active) {
        return PRODUCTS.NONE
    }
    else {
        if (subscription.validUntil === 'JAN-25') return PRODUCTS.JAN_25_OVERDUE
        if (subscription.validUntil === 'JAN-26') return PRODUCTS.JAN_26_DEFAULT


        // Bolsista
        if (subscription.validUntil.startsWith('JAN-26-DEFAULT-B1')) return PRODUCTS.JAN_26_DEFAULT

        // Embaixador
        if (subscription.validUntil.startsWith('JAN-26-DEFAULT-EMBAIXADORES')) return PRODUCTS.JAN_26_DEFAULT


        // Grande migué, onde a duração guarda o produto!!!
        return subscription.validUntil
    }
        
}


export function getAvailableProducts(currentProduct) {
    switch (currentProduct) {
        case PRODUCTS.NONE:
            return [
                // PRODUCTS.JAN_26_BASICO,                
                // PRODUCTS.JAN_26_BASICO_AEQUANIMITAS,
                // PRODUCTS.JAN_26_BASICO_LATCH_KEYERS,

                PRODUCTS.JAN_26_DEFAULT,
                PRODUCTS.JAN_26_DEFAULT_AEQUANIMITAS,
                PRODUCTS.JAN_26_DEFAULT_LATCH_KEYERS,

                // bolsas
                // PRODUCTS.JAN_26_DEFAULT_B1,
                // PRODUCTS.JAN_26_DEFAULT_B2,
                // PRODUCTS.JAN_26_DEFAULT_B3,
                // PRODUCTS.JAN_26_DEFAULT_B4,
                // PRODUCTS.JAN_26_DEFAULT_B5,
                // PRODUCTS.JAN_26_DEFAULT_B6,
                // PRODUCTS.JAN_26_DEFAULT_B7,
                // PRODUCTS.JAN_26_DEFAULT_B8,
                // PRODUCTS.JAN_26_DEFAULT_B9,
                // PRODUCTS.JAN_26_DEFAULT_B10,
                // PRODUCTS.JAN_26_DEFAULT_B11,
                // PRODUCTS.JAN_26_DEFAULT_B12,
                // PRODUCTS.JAN_26_DEFAULT_B13,
                // PRODUCTS.JAN_26_DEFAULT_B14,
                // PRODUCTS.JAN_26_DEFAULT_B15,
                // PRODUCTS.JAN_26_DEFAULT_B16
            ]
    
    
        case PRODUCTS.JAN_25_OVERDUE:
            return [
                PRODUCTS.JAN_26_RENEWAL,
                PRODUCTS.JAN_26_RENEWAL_AEQUANIMITAS,
                PRODUCTS.JAN_26_RENEWAL_LATCH_KEYERS,

                // bolsas
                // PRODUCTS.JAN_26_DEFAULT_B1,
                // PRODUCTS.JAN_26_DEFAULT_B2,
                // PRODUCTS.JAN_26_DEFAULT_B3,
                // PRODUCTS.JAN_26_DEFAULT_B4,
                // PRODUCTS.JAN_26_DEFAULT_B5,
                // PRODUCTS.JAN_26_DEFAULT_B6,
                // PRODUCTS.JAN_26_DEFAULT_B7,
                // PRODUCTS.JAN_26_DEFAULT_B8,
                // PRODUCTS.JAN_26_DEFAULT_B9,
                // PRODUCTS.JAN_26_DEFAULT_B10,
                // PRODUCTS.JAN_26_DEFAULT_B11,
                // PRODUCTS.JAN_26_DEFAULT_B12,
                // PRODUCTS.JAN_26_DEFAULT_B13,
                // PRODUCTS.JAN_26_DEFAULT_B14,
                // PRODUCTS.JAN_26_DEFAULT_B15,
                // PRODUCTS.JAN_26_DEFAULT_B16
            ]


        case PRODUCTS.JAN_26_RENEWAL:
        case PRODUCTS.JAN_26_DEFAULT:
            return [
                PRODUCTS.JAN_26_DEFAULT_UPSELL_AEQUANIMITAS,
                PRODUCTS.JAN_26_DEFAULT_UPSELL_LATCH_KEYERS,
            ]


        case PRODUCTS.JAN_26_DEFAULT_UPSELL_AEQUANIMITAS:
        case PRODUCTS.JAN_26_RENEWAL_AEQUANIMITAS:
        case PRODUCTS.JAN_26_DEFAULT_UPSELL_AEQUANIMITAS:
            return [
                PRODUCTS.JAN_26_DEFAULT_UPSELL_LATCH_KEYERS,
            ]

        default:
            return []
    }
}


export function canBuyGivenProduct(user, product) {
    const availableProducts = getAvailablePlans(user)
    return availableProducts.includes(product)
}


export function getAvailablePlans(user) {
    const currentProduct = getCurrentProduct(user.subscription)

    console.log(currentProduct)

    const availableProducts = getAvailableProducts(currentProduct)

    return availableProducts
}