import React from 'react'
import { useSelector } from 'react-redux'
import ConfigIcon from './../assets/config.png'
import styled from 'styled-components';
import OslerButton from '../components/OslerButton';
import { useNavigate } from 'react-router-dom';
import { getReadableProduct } from './checkout/CheckoutData';


const SubscriptionData = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 768px) {
        padding: 0.5em 0;
        
        p {
            font-size: 0.9em;
            margin: 0.6em 0;
            line-height: 1.4;
            width: 100%;
        }

        ul {
            width: 100%;
            padding-left: 1.2em;
            margin: 0.5em 0;
        }

        li {
            font-size: 0.9em;
            margin-bottom: 0.7em;
            line-height: 1.3;
        }
    }
`


const Status = styled.span`
    font-weight: bold;

    ${({ status, theme }) => {
        switch (status) {
            case 'active':
                return `
                    color: ${theme.darkMode ? '#90EE90' : '#1c8501'};
                `;
            default:
                return `
                    color: ${theme.darkMode ? 'rgba(255, 71, 87, 0.8);' : '#b30000'};
                `;
        }
    }}
    
    @media (max-width: 768px) {
        font-size: 0.9em;
        padding: 0.15em 0.35em;
    }
`;


const SettingsIcon = styled.img`
    width: 3em;
    margin: 0 0 1em 0;
    align-self: center;

    @media (max-width: 768px) {
        width: 2.5em;
        margin: 0.8em 0;
    }
`


export default function Subscription({subscriptionType}) {
    const user = useSelector(state => state.user.data)    
    const subs = user.subscription 

    const navigate = useNavigate()


    function getMonthly() {
        // Retorna mensagem para o usuário com assinatura MENSAL.
        let status;

        if (subscriptionType === 'monthly-paid') {
            status = (<Status status = 'active'>ativa</Status>)
        }
        else {
            status = (<Status status = 'stillActive'>pagamento pendente</Status>)
        }

        return (
            <>

            <p> {user.firstName}, seguem os dados da sua assinatura: </p>
            
            <ul>
                <li key={-1}>
                    <b>Email:</b> {user.email}
                </li>
                <li key={0}>
                    <b>Status:</b> { status }
                </li>   
                <li key={1}>
                    <b>Preço:</b> R${subs.amount_decimal / 100}/mês
                </li>
                <li key={2}>
                    <b>Último pagamento:</b> { subs.lastUpdated }
                </li>
            </ul>
            
            <SettingsIcon src={ConfigIcon}/>

            <p><b>Nós não vendemos mais assinaturas mensais!</b> Mas a sua continuará ativa enquanto você pagar. O preço que você paga atualmente provavelmente é um desconto expressivo em relação ao atual, então <u>cuidado para não perder sua assinatura</u>!</p>

            <p><b>Se precisar <u>cancelar</u> ou <u>mudar o cartão de crédito</u></b>, por favor, envie um email para suporte@osler-ensino.com</p>

        </>
        )
    }




    function getYearly() {
        function getDuration() {
            switch (user.subscription.validUntil) {
                case 'JAN-25' :
                    return '31 de Janeiro de 2025'
    
                case 'JAN-26':
                    return '31 de Janeiro de 2026'
                    
                default: 
                    return '(data indefinida — por favor, entre em contato com a gente!)'
            }
        } 


        // Retorna mensagem para o usuário com assinatura ANUAL.
        const until = getDuration()

        // Não precisamos checar se foi cancelada, do contrário essa página nem carrega.
        return (
            <>
            <p> {user.firstName}, seu plano é: <b>{ getReadableProduct(user) }</b>. 🧠 💪 ♥️</p>

            <p><b>Muito obrigado por ser nosso cliente!</b> Se precisar de qualquer coisa ou tiver sugestões, estamos à sua disposição.</p>
        </>
        )
    }




    function getContent() {
        console.log(subscriptionType)

        if (subscriptionType === 'monthly-paid' || subscriptionType === 'monthly-pending') {
            return (
                <>
                    {getMonthly()}
                </>
            )
        }
        else {
            return (
                <>
                { getYearly()}
                </>
            )
        }
    }


    return (
        <SubscriptionData>
            { getContent() }

            <OslerButton
                text = 'Renovação & Mentoria'
                onClick = {() => navigate('/buy')} />
                
        </SubscriptionData>
    )
    

}