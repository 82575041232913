import styled from 'styled-components';
import { latestVersionHardCoded } from '../App';

const VersionContainer = styled.div`
  width: 100%;
padding: -1em;

`

const VersionText = styled.span`
  color: gray;
  font-size: 0.6rem;
`;

export default function Version() {
  const env = process.env.REACT_APP_ENV

  return (
    <VersionContainer>
      <VersionText>Versão {latestVersionHardCoded} ({env})</VersionText>
    </VersionContainer>
  );
}