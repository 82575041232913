import styled from 'styled-components'
import Logo2 from './../assets/signature.png'
import ShieldIcon from './../assets/shield.png'
import ShoppingCart from './../assets/shopping-cart.png'

const GuaranteesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 24px;
    width: 100%;
    max-width: 1200px;
    margin-top: 48px;
    padding: 0 16px;
`

const GuaranteeBox = styled.div`
    text-align: center;
`

const GuaranteeIcon = styled.div`
    height: 2.2em;
    margin: 0 auto 12px;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`

const GuaranteeTitle = styled.h3`
    font-size: 1.4em;
    color: #1e293b;
    margin-bottom: 8px;
    font-weight: 600;
`

const GuaranteeText = styled.p`
    color: #64748b;
    font-size: 14px;
    font-size: 1.2em;
    line-height: 1.5;
`

export default function Guarantees() {
    return (
        <GuaranteesContainer>
            <GuaranteeBox>
                <GuaranteeIcon>
                    <img 
                        src={Logo2}
                        alt="Ícone de Compromisso"
                    />
                </GuaranteeIcon>
                <GuaranteeTitle>
                    Compromisso com Você
                </GuaranteeTitle>
                <GuaranteeText>
                    Nossa missão é seu sucesso. Garantimos suporte completo 
                    e conteúdo atualizado para maximizar seus resultados.
                </GuaranteeText>
            </GuaranteeBox>

            <GuaranteeBox>
                <GuaranteeIcon>
                    <img 
                        src={ShieldIcon}
                        alt="Ícone de Garantia"
                    />
                </GuaranteeIcon>
                <GuaranteeTitle>
                    Garantia de 7 Dias
                </GuaranteeTitle>
                <GuaranteeText>
                    Sem risco: nos primeiros 7 dias, você pode pedir cancelamento 
                    e receberá reembolso integral.
                </GuaranteeText>
            </GuaranteeBox>

            <GuaranteeBox>
                <GuaranteeIcon>
                    <img 
                        src={ShoppingCart} 
                        alt="Ícone de Pagamento"
                    />
                </GuaranteeIcon>
                <GuaranteeTitle>
                    Pagamento Facilitado
                </GuaranteeTitle>
                <GuaranteeText>
                    Cartão de crédito em até 12x com acesso imediato, 
                    ou boleto à vista com acesso na compensação.
                </GuaranteeText>
            </GuaranteeBox>
        </GuaranteesContainer>
    )
}