import React, { useEffect, useState } from 'react'
import PaymentProcessing from './PaymentProcessing';
import PaymentRefused from './PaymentRefused';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../redux/loadingSlice';
import { getSubscriptionType, reloadUser, shouldAllowPayment } from '../firebase/firebaseUtils';
import Upsell from './Upsell';
import ScreenContainerHeader from '../components/ScreenContainerHeader';

import ShoppingCart from './../assets/shopping-cart.png'

export default function Buy(props) {
    const dispatch = useDispatch()

    const [subscriptionType, setSubscriptionType] = useState(false)
    const [buyingAllowed, setBuyingAllowed] = useState(false)
    const [pendingSomething, setPendingSomething] = useState(false)

    const [loaded, setLoaded] = useState(false)


    async function loadScreen() {
        dispatch(setIsLoading(true))

        const user = await reloadUser()

        const subscriptionTypeTmp = getSubscriptionType(user)

        console.log(user)
        console.log(subscriptionTypeTmp)
        console.log( shouldAllowPayment(subscriptionTypeTmp) )
        console.log(['yearly-pending', 'yearly-refused'].includes(subscriptionTypeTmp))


        setPendingSomething(['yearly-pending', 'yearly-refused'].includes(subscriptionTypeTmp))

        setSubscriptionType(subscriptionTypeTmp)
        setBuyingAllowed( shouldAllowPayment(subscriptionTypeTmp) )


        dispatch(setIsLoading(false))
    }


    useEffect(() => {
        loadScreen()
    }, [])

    return (
        <ScreenContainerHeader
            icon = {ShoppingCart}
            title = 'Comprar'>

            { !pendingSomething && 
                <Upsell />
            }
            
            { pendingSomething && subscriptionType === 'yearly-pending' &&
                <PaymentProcessing verify = { loadScreen } />
            }
            { pendingSomething && subscriptionType === 'yearly-refused' &&
                <PaymentRefused verify = { loadScreen } />
            }

        </ScreenContainerHeader>
    )
}