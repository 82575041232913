import styled from 'styled-components'
import Guarantees from './Guarantees'
import PlanBox from './PlanBox'
import { getAvailablePlans, getAvailableProducts, PRODUCTS } from './checkout/CheckoutData'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'


import SignatureLogo from './../assets/signature.png'
import Aequanimitas from './../assets/aequanimitas.png'
import LatchKeyers from './../assets/latchKeyers.png'
import { setIsLoading } from '../redux/loadingSlice'
import { functions } from '../firebase/firebase-setup'
import { valueToReadable } from '../utils/Utils'


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
`

const SectionTitle = styled.h2`
    font-size: 32px;
    color: #1e293b;
    margin-top: 64px;
    margin-bottom: 24px;
    text-align: center;
    width: 100%;
`


const PlansContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 32px;
    width: 100%;

    max-width: 1800px;

    margin-top: 2em;
    margin-bottom: 64px;
`


const oslerProduct = {
    title : 'Plataforma da Osler',
    detail: 'Acesso até 31 de Janeiro de 2026',
    icon: SignatureLogo
}

const aequanimitasProduct = {
    title: 'Mentoria Aequanimitas',
    detail: 'Durante o ano de 2025',
    icon: Aequanimitas
}

const latchKeyersProduct = {
    title: 'Mentoria Latch Keyers',
    detail: 'Durante o ano de 2025',
    icon: LatchKeyers
}


const defaultPlan = {
    title: 'Osler',
    // description: 'Acesso completo à plataforma da Osler',
    highlighted: false,
    benefits: [
        '+47.000 flashcards baseados no UpToDate e em livros clássicos',
        '+43.000 questões, de todo o Brasil, todas comentadas',
        'Extensivo, Semi-Extensivo, Intensivão',
        'Provas na íntegra e em pdf',
        'Estatísticas detalhadas',
        'Simulados com questões próprias (2025)',
        'Material de multimídia',
        'Crie resumos associados aos cards e questões',
        'Desconto expressivo na renovação'
    ],
    products: [oslerProduct]
}


const aequanimitasPlan = {
    title: 'Osler + Aequanimitas',
    // description: 'Preparação intensiva para residência com mentoria personalizada',
    highlighted: true,
    benefits: [
        'Acesso à Osler',
        'Aulas semanais sobre método de estudos e prática médica',
        'Acesso à experiência de alunos que usaram a Osler para ser aprovados',
        'Sessões de dúvidas semanais, ao vivo'
    ],
    products: [oslerProduct, aequanimitasProduct]
}


const latchKeyersPlan = {
    title: 'Osler + Latch Keyers',
    // description: 'Programa exclusivo para alta performance nos estudos',
    highlighted: false,
    benefits: [
        'Acesso à Osler',
        'Acesso à Mentoria Aequanimitas',
        'Contato próximo com mentor aprovado pela Osler, em grupos pequenos',
        'Reunião mensal com a equipe da Osler'
    ],
    products: [oslerProduct, aequanimitasProduct, latchKeyersProduct]
}




const allPlans = {
    [PRODUCTS.JAN_26_DEFAULT]: {
        ...defaultPlan,
    },
    
    [PRODUCTS.JAN_26_DEFAULT_AEQUANIMITAS]: {
        ...aequanimitasPlan,
    },

    [PRODUCTS.JAN_26_DEFAULT_LATCH_KEYERS]: {
        ...latchKeyersPlan,
    },

    [PRODUCTS.JAN_26_RENEWAL]: {
        ...defaultPlan,
    },

    [PRODUCTS.JAN_26_RENEWAL_AEQUANIMITAS]: {
        ...aequanimitasPlan,
    },

    [PRODUCTS.JAN_26_RENEWAL_LATCH_KEYERS]: {
        ...latchKeyersPlan,
    },

    [PRODUCTS.JAN_26_DEFAULT_UPSELL_AEQUANIMITAS] : {
        title: 'Aequanimitas',
        // description: 'Preparação intensiva para residência com mentoria personalizada',
        benefits: [
            'Aulas semanais sobre método de estudos e prática médica',
            'Acesso à experiência de alunos que usaram a Osler para ser aprovados',
            'Sessões de dúvidas semanais, ao vivo'
        ],
        products: [aequanimitasProduct]
    },

    [PRODUCTS.JAN_26_DEFAULT_UPSELL_LATCH_KEYERS] : {
        title: 'Latch Keyers',
        benefits: [
            'Acesso à Mentoria Aequanimitas',
            'Contato próximo com mentor aprovado pela Osler, em grupos pequenos',
            'Reunião mensal com a equipe da Osler'
        ],
        products: [aequanimitasProduct, latchKeyersProduct]
    }
};



export default function Upsell() {
    const navigate = useNavigate()
    const user = useSelector(state => state.user.data)


    const dispatch = useDispatch()
    const [plans, setPlans] = useState([])

    const [noPlans, setNoPlans] = useState(true)

    useEffect(() => {
        async function load() {

            dispatch(setIsLoading(true))
            const availablePlans = getAvailablePlans(user)

            console.log(user)
            console.log(availablePlans)
            
            const availablePlanObjects = await Promise.all(
                availablePlans.map(async (planKey) => {
                  const getPlans = functions.httpsCallable('getTransactionPlan');
                  const response = await getPlans({ planID: planKey });
                  
                  if (response.data) {
                    const { amount } = response.data;
                    return {
                      planKey,
                      ...allPlans[planKey],
                      price: valueToReadable(amount / 100),
                      priceDetail: `ou 12x de ${valueToReadable((amount / 100) / 12)}`,
                    };
                  }
                })
              );
    
    
            setPlans(availablePlanObjects)
    
            dispatch(setIsLoading(false))
        }

        load()
    }, [user])


    const handlePurchase = (plan) => {
        console.log(plan)
        navigate('/checkout', {
            state: {
                selectedPlan: plan
            }
        })
    }

    return (
        <Container>
            <SectionTitle>Ciclo Clínico, Internato, e preparação para o R1</SectionTitle>

                <>
                <PlansContainer>
                    {plans.map(plan => (
                        <PlanBox
                            plan={plan}
                            onPurchase={handlePurchase}
                        />
                    ))}
                </PlansContainer>


                <Guarantees />
                </>
            
        </Container>
    )
}